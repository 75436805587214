// import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import Home from '../views/home.vue';
import Readme from '../views/readme_document.vue';

const routes = [
  {
    path: '/',
    name: '首页',
    component: Home,
  },
  {
    path: '/readme',
    name: '支持文档',
    component: Readme,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = '挚彬Club - ' + to.name; // 设置页面标题为路由的名称
  next();
});

export default router