<template>
    <div style="height: 1px; background: rgba(255, 255, 255, 0.2); width: 100%;margin-top: 100px;"></div>
    <div class="footer">
        <div class="footer-content">
            <div class="item_style"><img src="../assets/logo.svg" alt=""></div>
            <div class="item_style">
                <span class="title">相关账号</span>
                <a href="https://space.bilibili.com/408741517" target="_blank" class="item">哔哩哔哩企划官号</a>
                <a href="https://space.bilibili.com/3546607322663741" target="_blank" class="item">哔哩哔哩直白人类官号</a>
                <a href="https://music.163.com/#/user/home?id=257916982" target="_blank" class="item">挚彬（网易云音乐主页）</a>
            </div>
            <div class="item_style">
                <span class="title">友情链接</span>
                <a href="https://www.diffsinger.com" target="_blank" class="item">DiffSinger导航</a>
                <a href="https://xstudio.music.163.com" target="_blank" class="item">网易云音乐 · XStudio</a>
                <a href="https://www.aisingers.com" target="_blank" class="item">AISingers</a>
                <a href="https://www.vsqx.top" target="_blank" class="item">VSQX分享站</a>
            </div>
            <div class="item_style">
                <span class="title">网站信息</span>
                <a href="https://beian.miit.gov.cn/" target="_blank" class="item">闽ICP备2021000177号-1</a>
                <span class="item">© Copyright {{ copyright_year }} 挚彬Club. All Rights Reserved</span>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'HelloFooter',
    props: {
        msg: String
    },
    data() {
        return {
            copyright_year: new Date().getFullYear()
        }
    }
}
</script>
<style>
.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-content {
    width: 75%;
    display: flex;
    flex-direction: row;
    min-width: 1240px;
    padding: 100px 0px 100px 0px;
    gap: 100px;
    align-self: stretch;
    justify-content: space-between;
    div {
        display: flex;
        /* align-items: center; */
        flex-direction: column;
        gap: 20px;
    }
}

.title {
    color: #EBEBEB;
    font-size: 22px;
    font-weight: 500;
}

.item {
    font-size: 16px;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: 0px;
    color: #EBEBEB;
}
.item_style{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    
    img{
        width: fit-content;
    }
}
</style>