<template>
    <n-config-provider :theme-overrides="themeOverrides" class="layout">

        <My_header></My_header>
        <n-back-top :right="100" />
        <div class="container">
            <div class="content">
                <!-- <div class="content_text"><h1>{{title}}</h1></div> -->
                <div class="content_text" v-html="content"></div>
            </div>
        </div>
        <My_footer style="position: fixed;"></My_footer>
    </n-config-provider>
</template>

<script>
/* eslint-disable */
import My_header from '../components/header.vue'
import My_footer from '../components/footer.vue'
import { NBackTop } from 'naive-ui'
import { NConfigProvider } from 'naive-ui'
export default {
    name: 'Readme',
    components: {
        My_header,
        My_footer,
        NBackTop,
        NConfigProvider,
    },
    data() {
      return {
        content:'加载中',
        

  
      }
    },
    mounted() {
        fetch('https://api.zhibin.club/api/index/get_readme')
            .then(response => response.json())
            .then(data => {
                this.title = data.data[0].value;
                this.content = data.data[1].value;
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
}

</script>

<style>
.container {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1240px;
    flex-direction: column;
    margin-top: 126px;
}

.content {
    width: 100%;
    min-height: 100vh;
    background: #ffffff20;
    border-radius: 10px;
}

.content_text{
    padding: 20px 40px;
    color: #fff;
}

html {
    scroll-behavior: smooth;

}

body {
    scroll-behavior: smooth;
    margin: 0;
    background-color: #0F0F0F;
    min-height: 100vh;
    /* min-width: 1240px; */
    display: flex;
    flex-direction: column;

}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    background-color: #0F0F0F;
}
</style>