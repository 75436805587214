<template>
    <n-config-provider :theme-overrides="themeOverrides" class="layout">
        <My_header></My_header>
        <n-back-top :right="100" />
        <div class="container">
            <!-- 头部轮播 -->
            <swiper v-if="banner.length > 0" :loop="true" :autoplay="{
                delay: 6000,
                disableOnInteraction: false,
            }" :pagination="{
        clickable: true,
    }" :navigation="true" :modules="modules">
                <swiper-slide v-for="item in banner" :key="item.id">
                    <div class="banner">
                        <div class="title_item">
                            <span class="title" v-html="item.title"></span>
                            <span class="subtitle" v-html="item.sub_title"></span>
                            <a :href="'mailto:' + item.cpr_conecter" class="subtitle">© 合作授权联系</a>
                            <a :href="item.button_url" target="_blank"><n-button class="button" type="primary" round
                                    strong size="large">{{ item.button_text
                                    }}</n-button></a>
                        </div>
                        <img class="rimg" height="100%" :src="item.img" alt="">
                    </div>
                </swiper-slide>
            </swiper>
            <!-- 头部轮播结束 -->
            <div class="news">
                <div class="content_title">
                    <span>最新动态</span>
                    <img src="../assets/line.svg" alt="">
                </div>
                <n-carousel loop autoplay direction="vertical" dot-placement="right" show-arrow
                    style="width: 100%; height: 400px; border-radius: 20px;">
                    <a :href="item.url" v-for="item in news" :key="item.id">
                        <img class="carousel-img" :src="item.img">
                    </a>

                </n-carousel>
            </div>
            <div class="news" id="singers">
                <div class="content_title">
                    <span>声库获取</span>
                    <img src="../assets/line.svg" alt="">
                </div>
                <div class="singer_layout">
                    <div class="singer_list">
                        <div style="display: flex; align-items: center; font-size: 14px;">
                            <input style=" width: 100%; height: 30px; border-radius: 99px;text-align: center;"
                                placeholder="搜索" v-model="keywrods" />


                            <n-button style="width: 80px; margin-left: 20px;" type="primary" round strong
                                @click="get_singers()">搜索</n-button>
                        </div>
                        <n-scrollbar style="max-height: 900px;border-radius: 20px;margin-top: 10px;" trigger="hover">
                            <div class="singer_info" v-for="(item, index) in singer_list" :key="index"
                                @click="selectSinger(index)">
                                <div class="singer_info_item"
                                    :class="{ 'activate': index == select_singer, 'unactivate': index != select_singer }">
                                    <img class="singer_info_img" :src="item.head">
                                    <div class="singer_info_title">
                                        <span style="font-size: 26px;">{{ item.name }}</span>
                                        <span style="font-size: 16px;color: ffffff50;font-weight: 500;">{{ item.engine
                                            }}</span>
                                        <div
                                            style="padding: 2px 5px;font-size: 16px;font-weight: 300;color: #ff6666;border: #ff6666 1px solid;border-radius: 5px;width: fit-content;">
                                            <span v-if="item.is_member">本家歌手</span>
                                            <span v-else>友情歌手</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </n-scrollbar>
                    </div>
                    <div class="singer_detial">
                        <div class="singer_detial_left">
                            <div style="display: flex;align-items: center;">
                                <span style="font-size: 40px">{{ singer_info.name }}</span>
                                <a :href="singer_info.down_url" target="_blank"><n-button class="button" type="primary"
                                        round strong size="large" style="margin-left: 20px;">下载</n-button></a>
                            </div>
                            <span style="font-size: 20px;font-weight: 300;color: #ffffff60;">歌手标签：{{ singer_info.tags
                                }}</span>
                            <span style="font-size: 20px; font-weight: 400; color: #ffffff80; margin-top: 20px;">
                                性别：
                                {{ singer_info.sex === 0 ? '未知' : singer_info.sex === 1 ? '男' : singer_info.sex === 2 ?
                                '女' : '这是个啥' }}
                            </span>
                            <span style="font-size: 20px;font-weight: 400;color: #ffffff80;">年龄：{{ singer_info.age
                                }}</span>
                            <span style="font-size: 20px;font-weight: 400;color: #ffffff80;">主色：{{ singer_info.color
                                }}</span>
                            <span style="font-size: 20px;font-weight: 400;color: #ffffff80;">版本：{{ singer_info.version
                                }}</span>
                            <span style="font-size: 20px;font-weight: 400;color: #ffffff80;">身高：{{ singer_info.height
                                }}</span>
                            <span style="font-size: 20px;font-weight: 400;color: #ffffff80;">体重：{{ singer_info.weight
                                }}</span>
                            <span style="font-size: 20px;font-weight: 400;color: #ffffff80;">生日：{{ singer_info.birth
                                }}</span>
                            <span style="font-size: 20px;font-weight: 400;color: #ffffff80;">声源：{{ singer_info.voice
                                }}</span>
                            <span style="font-size: 20px;font-weight: 400;color: #ffffff80;">画师：{{ singer_info.drawby
                                }}</span>
                            <span style="font-size: 20px;font-weight: 400;color: #ffffff80;">描述：{{ singer_info.detial
                                }}</span>
                            <span style="font-size: 20px;font-weight: 400;color: #ffffff80;">预览：<a
                                    :href="singer_info.preview" style="color: #ff6666" target="_blank">点击查看</a></span>
                            <span style="font-size: 20px;font-weight: 400;color: #ffffff80;">归属：{{ singer_info.belong
                                }}</span>
                            <span style="font-size: 20px;font-weight: 400;color: #ffffff80;">E-Mail：{{ singer_info.mail
                                }}</span>
                        </div>
                        <div class="singer_detial_right">
                            <img style="height: 100%;width: 100%; object-fit: contain;" :src="singer_info.img" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="news" id="cooperate">
                <div class="content_title">
                    <span>友情导航</span>
                    <img src="../assets/line.svg" alt="">
                </div>
                <div class="cooperate_layout">
                    <a :href="item.url" target="_blank" v-for="item in cooperates" :key="item.id"><img
                            class="cooperate_item" :src="item.img"></a>

                </div>
            </div>
        </div>
        <My_footer></My_footer>
    </n-config-provider>
</template>

<script>
/* eslint-disable */
import My_header from '../components/header.vue'
import My_footer from '../components/footer.vue'
import { defineComponent } from 'vue'
import { NButton } from 'naive-ui'
import { NConfigProvider } from 'naive-ui'
import { NThemeEditor } from 'naive-ui'
import { NCarousel } from 'naive-ui'
import { NBackTop } from 'naive-ui'
import { NInput } from 'naive-ui'
import { NIcon } from 'naive-ui'
import { NScrollbar } from 'naive-ui'

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import axios from 'axios';
/**
 * js 文件下使用这个做类型提示
 * @type import('naive-ui').GlobalThemeOverrides
 */
const themeOverrides = {
    common: {
        "primaryColor": "#ff6666FF",
        "primaryColorHover": "#ff666688",
        "primaryColorPressed": "#ff666666",
        "primaryColorSuppl": "#ff6666FF"
    },
    Button: {
        textColor: '#fff',
    },
    Select: {
        peers: {
            InternalSelection: {
                textColor: '#fff'
            }
        }
    }
}

const onSwiper = (swiper) => {
    console.log(swiper);
};
const onSlideChange = () => {
    console.log('slide change');
};
export default {
    name: 'Home',
    components: {
        My_header,
        My_footer,
        NButton,
        NConfigProvider,
        NThemeEditor,
        Swiper,
        SwiperSlide,
        NCarousel,
        NBackTop,
        NInput,
        NIcon,
        NScrollbar,
    },
    data() {
        return {
            themeOverrides,
            onSwiper,
            onSlideChange,
            modules: [Autoplay, Pagination, Navigation],
            banner: [],
            news: [],
            singer_list: [],
            select_singer: 0,
            cooperates: [],
            singer_info: {},
            keywrods: '',

        }
    },
    methods: {
        get_banner() {
            axios.get('https://api.zhibin.club/api/index/get_banner_detail')
                .then(response => {
                    this.banner = response.data.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        get_news() {
            axios.get('https://api.zhibin.club/api/index/get_news_list')
                .then(response => {
                    this.news = response.data.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        get_singers(page = 1, limit = 99) {
            axios.post(`https://api.zhibin.club/api/index/get_singer_list?page=${page}&limit=${limit}&keywords=${this.keywrods}`)
                .then(response => {
                    if (response.data.data.length > 0) {
                        this.singer_list = response.data.data;
                        this.singer_info = this.singer_list[0]
                        this.select_singer = 0;
                    } else {
                        console.log('没有找到该歌手', this.keywrods)
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        selectSinger(index) {
            this.select_singer = index
            this.singer_info = this.singer_list[index]

        },
        get_cooperates() {
            axios.get('https://api.zhibin.club/api/index/get_cooperate')
                .then(response => {
                    this.cooperates = response.data.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        changeKey() {
            console.log(this.keywrods)
        }
    },
    mounted() {
        // 在组件挂载到 DOM 后触发，相当于 onload 事件
        // 可以在这里执行初始化操作、调用接口等
        this.get_banner();
        this.get_news();
        this.get_singers();
        this.get_cooperates();
    },
}
</script>

<style>
.cooperate_layout {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 100px;
    align-self: stretch;
    /* background: #ffffff10; */
}

.cooperate_item {
    height: 40px;
}



.singer_list {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: fit-content;
    transition: opacity 0.5s ease;

    .activate {
        background-color: #ffffff10;
    }

    .unactivate {
        background-color: #ffffff00;
    }
}

.singer_info_item {
    display: flex;
    padding: 10px;
    border-radius: 20px;
    align-items: center;
    width: 360px;

    img {
        width: 120px;
        height: 120px;
    }
}

.singer_info_img {
    width: 120px;
    height: 120px;
    border-radius: 10px;

}

.singer_info_title {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    gap: 10px 10px;
}

.singer_layout {
    display: flex;
    /* margin: 100px 0; */
    flex-direction: row;
    width: 100%;
    background-color: #00000030;
    /* padding: 20px; */
    border-radius: 20px;
    border: #ffffff60 1px solid;
    height: 1000px;
    overflow: hidden;
}

.singer_info {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border-radius: 20px;
    gap: 20px 20px;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
    flex-grow: 1;

    img {
        height: 120px;
        width: 120px;
        object-fit: cover;
        border-radius: 10px;
    }
}

.singer_detial {
    display: flex;
    flex-direction: row;
    background: #ffffff10;
    padding: 90px;
    width: 100%;
}

.singer_detial_left {
    display: flex;
    flex-direction: column;
    gap: 20px 20px;
    width: 70%;
}

.singer_detial_right {
    display: flex;
    width: 100%;
}

.carousel-img {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news {
    width: 100%;
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}

.content_title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 50px;
}

.swiper {
    --swiper-theme-color: #ffffff30;
    --swiper-pagination-color: #ff6666;
    /* 两种都可以 */
}

html {
    scroll-behavior: smooth;

}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    background-color: #0F0F0F;
}

.swiper {
    width: 100%;
    height: 100%;
}

body {
    scroll-behavior: smooth;
    margin: 0;
    background-color: #0F0F0F;
    min-height: 100vh;
    /* min-width: 1240px; */
    display: flex;
    flex-direction: column;

}

.layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1240px;
    flex-direction: column;
    margin-top: 96px;
}

.container .cpr {
    display: flex;
    color: "#ff6666";
}

.banner .rimg {
    height: 400px;
    /* 设置图片高度为父容器的高度 */
    object-fit: cover;
    /* 可选：根据需要调整图片的对象适应方式 */
}

.banner {
    display: flex;
    margin: 100px;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;


}

.banner .title {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    font-size: 50px;
    line-height: normal;
    letter-spacing: 0em;
    width: 632px;
    height: 132px;
}

.banner .subtitle {
    width: 555px;
    height: 60px;
    opacity: 1;

    font-family: MiSans;
    font-size: 16px;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: 0px;

    color: rgba(235, 235, 235, 0.8);

}

.banner .title_item {
    width: 632px;
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px;
    align-self: stretch;
}

.banner .button {
    width: 240px;
}
</style>