<template>
    <div class="header">
        <div class="header_container">
            <a href="/"><img class="logo" src="../assets/logo.svg" alt=""></a>
            <div class="menu">
                <a href="/#singers" class="menu_item">
                    <img class="menu_icon" src="../assets/download.svg" alt="">
                    <span>声库获取</span>
                </a>
                <a href="/readme" class="menu_item">
                    <img class="menu_icon" src="../assets/help.svg" alt="">
                    <span>支持文档</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelloWorld',
    props: {
        msg: String
    }
}
</script>

<style>
a {
    color: white;
    text-decoration: none;
}

.header_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-self: center;
    /* background-color: #000; */
    color: white;
    margin: 0 200px;
    text-align: center;
    justify-content: space-between;
}

.header {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #0F0F0F80;
    color: white;
    padding: 30px 0;
    z-index: 999;
    backdrop-filter: blur(66px);
}

.logo {
    height: 36px;
}

.menu_icon {
    height: 24px;
}

.menu {
    a {
        margin-left: 20px;
    }

    display: flex;
    align-items: center;
    min-width: 236px;

}

.menu_item {
    span {
        margin-left: 10px;
    }

    display: flex;
    align-items: center;
}
</style>